import React from 'react';
import HeaderComponent from '../Headers/darkHeader';
import IntroAbout from './IntroAbout';
import ReportPageHolder from './ReportPageHolder';
import Footer from '../Footer/GreenFooter';

function ServicesPage() {
  return (
    <div>
      <HeaderComponent firstSectionClass="intro-section" />
      <IntroAbout className="intro-section" />
      <ReportPageHolder />
      <Footer />
    </div>
  );
}

export default ServicesPage;
