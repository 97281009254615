import React, { useState, useEffect } from 'react';
import './IntroText.css'; // Import the CSS for styling

// Assuming you have these images stored in the src/assets directory
import personImage from './Mask groupWomen holding tablet.png';

const IntroText = ({ className }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`hero-section ${className}`}>
      <img src={personImage} alt="Person holding a tablet" className="person-image" />
      <div className="home-page-intro-content">
        <h1>
          Empowering {isMobile && <br />}
          {isMobile ? <b>Your Health</b> : 'Your Health'} {isMobile && <br />}
          {isMobile ? <b>Journey</b> : 'Journey'}
        </h1>
        <p>with Expert Online Care</p>
        <button className="cta-button"
          onClick={() => {
            window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank');
          }}>
          Start With Free Consult
        </button>
      </div>
    </div>
  );
}

export default IntroText;
