import React from 'react';
import './SecondPage.css';
import { Link } from 'react-router-dom'; // Import Link component

// Example logos (replace with actual logo paths)
import logo1 from './logo1.png';
import logo2 from '../Logos/HomePage/Services/Lab.png';
import logo3 from '../Services/logos/Cardiac.png';
import logo4 from '../HowItWorks/photos/Layer_Access.png';

function SecondPage() {
    return (
        <div className="second-page">
            <div className="second-page-content">
                <h1>We Prevent Illness</h1>
                <p>by Focusing on the Foundations of Health</p>

                <div className="cards-container">
                <Link to="/membership" className="card">
                        <img src={logo1} alt="Logo 1" />
                        <p>Ultra Personalized Care</p>
                    </Link>
                    <Link to="/services" className="card">
                        <img src={logo2} alt="Logo 2" />
                        <p>Lab, Genetic, and Nutrition Testing</p>
                    </Link>
                    <Link to="/services" className="card">
                        <img src={logo3} alt="Logo 3" />
                        <p>Heart Attack and Stroke Prevention</p>
                    </Link>
                    <Link to="/membership" className="card">
                        <img src={logo4} alt="Logo 4" />
                        <p>24/7 Access to Your Personal Physician</p>
                    </Link>
                </div>

                <div className='service-paragraph'>
                  For Only $250 A Month
                </div>
                
            </div>
        </div>
    );
}

export default SecondPage;
