import React, { useState, useEffect } from 'react';
import './FloatingButton.css'; // Make sure to create this CSS file

function FloatingButton({ onClick }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.5) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      className={`floating-button ${isVisible ? 'visible' : ''}`}
      onClick={() => {
        window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank');
      }}
    >
      Start With Free Consult
    </button>
  );
}

export default FloatingButton;
