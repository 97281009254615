import React from 'react';
import './IntroAbout.css'; // Import your CSS file for styles

import largeGreenSemiCircle from './Photos/LargeGreenSemiCricle.png';
import yellowCircle from './Photos/DarkYelloCircle.png';
import yellowSemiCircle from './Photos/DarkYellowSemiCircle.png';

function IntroAbout({ className }) {
  return (
    <div className={`aboutUsContainer ${className}`} style={{ position: 'relative', color: 'white', fontFamily: 'Arial, sans-serif' }}>
      <img src={largeGreenSemiCircle} alt="Large Green Semi Circle" className="large-green-semi-circle" />
      <img src={yellowCircle} alt="Yellow Circle" className="yellow-circle-position" />
      <img src={yellowSemiCircle} alt="Yellow Semi Circle" className="yellow-semi-circle" />
      <div style={{ margin: '20px', position: 'relative', zIndex: 1 }}>
        <h1>About Us</h1>
        <p>"I started this practice after a decade of treating the nation’s sickest and most broken patients in <span className="highlight">emergency rooms</span>.</p>
        <p>I now <span className="highlight">focus on preventing illness</span> with 21<sup>st</sup> century tools that allow us to optimize your nutrition, improve your movement, mobility, and performance, and use cutting edge treatments that show real promise to increase your functional lifespan. We combine that with the <span className="highlight">best service in healthcare</span>.</p>
        <p>I’m interested in getting to know the real you, and helping you meet your <span className="highlight">goals</span>, whatever they are. We have patients of all shapes, sizes, and health status. I like to think it’s not where you start from, but where you’re headed that matters.</p>
        <p>I look forward to meeting with you, and helping you <span className="highlight">live a happy, healthy, and fulfilled life</span>."</p>
        <p>Dr. Ryan Kindervater</p>
      </div>
      <div class="about-button-container">
        <button className="about-cta-button"
            onClick={() => {
              window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank');
            }}>
            Start With Free Consult
        </button>
      </div>
    </div>
  );
}

export default IntroAbout;
