import React from 'react';
import Intro from './introworks';
import HeaderComponent from '../Headers/lightHeader';
import Membership from './membership';
import ContactUs from './contactus';
import ComparisonSection from './comparison';
import MoreQuestions from './morequestions';
import Footer from '../Footer/GreenFooter';

function HowItWorks() {
  return (
    <div>
      <HeaderComponent firstSectionClass="intro-section" />
      <Intro className="intro-section" />
      <Membership />
      <ContactUs />
      <ComparisonSection />
      <MoreQuestions />
      <Footer />
    </div>
  );
}

export default HowItWorks;
