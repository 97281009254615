import React, { useState } from 'react';
import './ThirdPage.css';
import { Link } from 'react-router-dom'; // Import Link component

// Replace these imports with the actual paths to your icon images
import icon1 from '../Logos/HomePage/Services/Nutrition.png';
import icon2 from '../Logos/HomePage/Services/Genetics.png';
import icon3 from '../Logos/HomePage/Services/Longevity.png';
import icon4 from '../Logos/HomePage/Services/Athletic.png';
import icon5 from '../Logos/HomePage/Services/Lab.png';
import icon6 from '../Logos/HomePage/Services/Disease.png';
import icon7 from '../Logos/HomePage/Services/Roadmap.png';
import icon8 from '../Logos/HomePage/Services/Physician Access.png';
import icon9 from '../Logos/HomePage/Services/Hormone.png';

const iconDetails = [
  { src: icon1, text: 'Nutrition', description: 'Nutritional assessments and personalized guidance from experts.', className: 'icon-size-nutrition' },
  { src: icon2, text: 'Personalized Medicine & Genetics', description: 'Custom health plans based on your genetic makeup and health history.', className: 'icon-size-personalized-medicine' },
  { src: icon3, text: 'Longevity Medicine', description: 'Advanced treatments and strategies for healthy aging.', className: 'icon-size-longevity-medicine' },
  { src: icon4, text: 'Athletic Performance', description: 'Tailored programs to enhance physical performance and recovery.', className: 'icon-size-athletic-performance' },
  { src: icon5, text: 'Advanced Lab Evaluation', description: 'Utilizing state-of-the-art testing for comprehensive health insights.', className: 'icon-size-advanced-lab' },
  { src: icon6, text: 'Disease Prevention', description: 'Focused on reducing the risk of heart disease, cholesterol issues, high blood pressure, fatigue, dementia, and more.', className: 'icon-size-disease-prevention' },
  { src: icon7, text: 'Custom Health Roadmaps', description: 'Actionable steps and strategies for your unique health journey.', className: 'icon-size-custom-health' },
  { src: icon8, text: '24/7 Physician Access', description: 'Unmatched communication and support from your dedicated health expert.', className: 'icon-size-physician-access' },
  { src: icon9, text: 'Hormone Optimization', description: 'Ensuring all your body’s systems are in sync. From supplementation to hormone replacement, our experts help you select the best treatment.', className: 'icon-size-hormone' },
];

function ThirdPage() {
  const [showMore, setShowMore] = useState(false);

  const columns = iconDetails.reduce((result, item, index) => {
    const columnIndex = index % 2;
    if (!result[columnIndex]) {
      result[columnIndex] = [];
    }
    result[columnIndex].push(item);
    return result;
  }, []);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="third-page">
      <div className="third-page-content">
        <h1>Tailored Health Solutions</h1>
        <p>for Every Aspect of Your Life</p>
        <div className="icons-container">
          <div className="icon-column">
            {columns[0].map((iconDetail, index) => (
              <div key={index} className="icon-item">
                <img src={iconDetail.src} alt={iconDetail.text} className={iconDetail.className} />
                <div className="icon-text">
                  <h2>{iconDetail.text}</h2>
                  <p>{iconDetail.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={`icon-column ${showMore ? 'show' : ''}`}>
            {columns[1].map((iconDetail, index) => (
              <div key={index} className="icon-item">
                <img src={iconDetail.src} alt={iconDetail.text} className={iconDetail.className} />
                <div className="icon-text">
                  <h2>{iconDetail.text}</h2>
                  <p>{iconDetail.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="see-more-button" onClick={handleShowMore}>
          {showMore ? 'See Less' : 'See More'}
        </button>
        <div className="third-page-buttons">
          <Link to="/services" style={{ textDecoration: 'none' }}>
            <button className="our-services">Our Services</button>
          </Link>
          <Link to="/membership" style={{ textDecoration: 'none' }}>
            <button className="learn-more">Learn More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThirdPage;
