const blogs = [
    {
        id: 1,
        title: 'Blog Post One',
        summary: 'This is a summary of the first blog post.',
        imageUrl: 'path/to/image1.jpg',
        content: 'This is the full content of the first blog post.',
    },
    {
        id: 2,
        title: 'Blog Post Two',
        summary: 'This is a summary of the second blog post.',
        imageUrl: 'path/to/image2.jpg',
        content: 'This is the full content of the second blog post.',
    },
    {
        id: 3,
        title: 'Blog Post Three',
        summary: 'This is a summary of the third blog post.',
        imageUrl: 'path/to/image3.jpg',
        content: 'This is the full content of the third blog post.',
    },
];

export default blogs;
