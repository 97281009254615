import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogDetail.css';
import blogs from './BlogData'; // Import blog data

function BlogDetail() {
    const { id } = useParams();
    const blog = blogs.find(blog => blog.id === parseInt(id));

    if (!blog) {
        return <h2>Blog not found</h2>;
    }

    return (
        <div className="blog-detail">
            <h1 className="blog-title">{blog.title}</h1>
            <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
            <div className="blog-content">{blog.content}</div>
        </div>
    );
}

export default BlogDetail;
