import React, { useState } from 'react';
import './faqmain.css'; // Make sure to import your CSS styles as specified
import ellipseYellowSunDown from './photos/Ellipse 25Yellow Sun Down.png';
import ellipseYellowSemiUp from './photos/Ellipse 27Yellow Semi Up.png';
import maskGroupGreenCircle from './photos/Mask groupGreen Circle.png';

const FaqMain = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [openQuestions, setOpenQuestions] = useState({});

  const toggleQuestion = (sectionKey, questionIndex) => {
    const isOpen = openQuestions[sectionKey] === questionIndex;
    setOpenQuestions({ ...openQuestions, [sectionKey]: isOpen ? -1 : questionIndex });
  };

  
  
  const faqs = {
    general: [
      { question: "How does Sapia Health function?", answer: "Sapia Health is an entirely online platform focused on functional and preventative medicine. We've built a medical practice centered around the idea of preventing illness before it occurs, offering the proactive, thoughtful care we'd seek out for ourselves." },
      { question: "How do I schedule an appointment?", answer: "Scheduling is straightforward with our app, 'Healthie.' You can set up your appointments in just a few seconds directly from your phone, bypassing the need for traditional appointment-setting hassles." },
      { question: "How long do appointments last?", answer: "Your initial session will range from 1-2 hours, providing a thorough assessment of your health. Follow-up visits, which are part of your membership, typically last about 30 minutes, ensuring we stay on top of your health goals." },
      { question: "Do I have a dedicated doctor?", answer: "Yes. You'll have a personal doctor who gets to know you and your health objectives, eliminating the need to rehash your medical history every time. This approach ensures continuity and a deeper understanding of your health journey." },
      { question: "If I already have a primary care doctor, how does Sapia Health fit in?", answer: "Sapia Health complements your existing healthcare by offering additional support and services beyond what your primary doctor provides. Whether you're looking for supplemental care or seeking us as your direct primary care provider, we're flexible and happy to work alongside your current healthcare setup." },
      { question: "Who are the Sapia Health physicians?", answer: "At Sapia Health, you'll be in the expert care of Dr. Christopher Nelson and Dr. Ryan Kindervater. They're our dedicated physicians committed to your health and well-being. (Note: For images or more detailed bios, our website offers a closer look at our team.)" },
      { question: "Where can I access Sapia Health services?", answer: "Our services are available in several states, including California, Washington, Utah, Montana, Idaho, Wyoming, Colorado, Nevada, Texas, and Vermont. Plus, we provide doctor-led nutrition counseling nationwide. If your state isn't listed, reach out to us—we're always looking to expand our care." },
      { question: "Do I need to visit in person?", answer: "Not at all! Our physician and nutrition services are entirely online, saving our patients an average of 9 hours a year in driving and waiting time. However, lab work will still need to be done in person—we're still working on the tech to do that online!" },
      { question: "How do I get my labs done?", answer: "We've teamed up with Quest and Labcorp, allowing you to have your labs drawn at any of their numerous locations at your convenience. Additionally, for a nominal fee, many cities offer the option to have lab work done right at your home or office, perfect for those with busy schedules." },
      { question: "Can Sapia Health prescribe medications?", answer: "Absolutely. Through electronic prescribing, we can send your prescriptions directly to your preferred pharmacy or have them delivered to your doorstep via an online pharmacy service." },
      { question: "What benefits does Sapia Health offer?", answer: "Think of us as a high-performance tune-up shop, but for your health. We fine-tune your nutrition, lab results, and physiological metrics, crafting a tailored health roadmap for you to follow. It's about optimizing your health in every aspect." },
      { question: "I haven't focused on my health in years. Can you help?", answer: "Certainly! Regardless of where you're starting from, we're here to guide you through each step towards better health. It's never too late to start focusing on your well-being." },
      { question: "I consider myself quite healthy. How can Sapia Health assist me?", answer: "Even if you're in great shape, there's always room for optimization. We provide a listening ear and expertise to help you maintain your health through personalized health plans, lab work, medication management, accountability, and prioritizing what's essential to keep you on track." }
    ],
    financial: [
      {
        question: "How much does Sapia Health cost?",
        answer: `
          <strong>All-Inclusive Membership</strong><br><br>
          Experience Comprehensive, Personalized Care<br><br>
          For just $250 per month* billed annually or $299 billed monthly, our All-Inclusive Membership offers unparalleled access to a full suite of healthcare services designed to keep you at your best:<br><br>
          <ul>
            <li><strong>Signature Comprehensive Intake:</strong> A detailed assessment to understand your unique health needs.</li>
            <li><strong>Quarterly Visits:</strong> Regular check-ins with your dedicated doctor to monitor and optimize your health.</li>
            <li><strong>Custom Nutrition Plan:</strong> Tailored to your genetics, health profile, and favorite foods, with a smart AI model that creates new meals daily and manages your shopping through Instacart.</li>
            <li><strong>Advanced Lab and Imaging Orders:</strong> Access to necessary tests whenever needed.</li>
            <li><strong>Convenient Prescriptions:</strong> Digital prescriptions sent directly to your pharmacy.</li>
            <li><strong>Annual Genetic Updates:</strong> Stay informed with yearly updates on genetic test results impacting your health.</li>
            <li><strong>Custom Supplement Plan:</strong> Personalized recommendations for optimal health.</li>
            <li><strong>Health Roadmap:</strong> A detailed plan with specific goals and follow-up steps.</li>
            <li><strong>24/7 Physician Access:</strong> Ongoing access to your physician for continuous support and guidance.</li>
          </ul><br>
          <strong>Sapia Flex Plan</strong><br><br>
          Flexible, On-Demand Healthcare<br><br>
          For a one-time fee of $799, the Sapia Flex Plan provides comprehensive care without the commitment of a subscription:<br><br>
          <ul>
            <li><strong>Signature Comprehensive Intake:</strong> Initial assessment to address your health needs.</li>
            <li><strong>Comprehensive Lab Evaluation:</strong> Full evaluation of your health status.</li>
            <li><strong>Advanced Imaging Orders:</strong> As needed to ensure accurate diagnoses.</li>
            <li><strong>Comprehensive Nutrition Evaluation:</strong> Detailed analysis to inform your custom nutrition plan.</li>
            <li><strong>Custom Nutrition Plan:</strong> AI-powered meal planner with automated shopping through Instacart.</li>
            <li><strong>Genetic Testing Interpretation:</strong> Understand your genetic results (testing billed separately).</li>
            <li><strong>Custom Supplement Plan:</strong> Personalized recommendations for supplements.</li>
            <li><strong>Health Roadmap:</strong> Clear next steps and personalized goals for your health journey.</li>
            <li><strong>24/7 Messaging:</strong> Direct access to your physician during the 60-day follow-up period.</li>
            <li><strong>Electronic Prescriptions:</strong> Conveniently managed prescriptions (monthly fee may apply).</li>
          </ul>`
      },
      { question: "Is Sapia Health covered by insurance?", answer: "We order labs with your insurance as the primary payor (coverage is specific to your plan). We offer Superbills so you can work with your insurance to get our fees covered. We also now take FSA and HSA cards!" },
      { question: "How does my intake at Sapia Health work?", answer: "You’ll complete our comprehensive intake on our secure app, log your diet information, and get labs drawn. Once all that is completed, you’ll have your comprehensive intake with your doctor where you will complete your health roadmap." },
      { question: "Are labs, testing, and meds an additional fee?", answer: "We avoid conflicts of interest by not directly billing for labs/testing/treatments. This means that we do not make ANY money off of testing or medications. It allows us to order and prescribe only the things that we feel are important without being incentivized to over-order or over-treat. We order all labs and testing with your insurance information, so that you can fully utilize the benefits of your plan." },
      { question: "Are there any tests NOT covered by insurance?", answer: "Genetic testing, done through our partner lab, is not eligible for insurance payment (almost none are), and is an industry-leading value at $500 for 30x Whole Genome Sequencing. Other tests like the Galleri 'liquid biopsy' blood-based cancer screening test, are not currently eligible for any insurance coverage, and are cash pay." }
    ]
  };
  
  

  
  return (
    <div className="faq-container">
      <h1 className="faq-h1">FAQs</h1>  
      <img src={maskGroupGreenCircle} alt="Decorative green circle" className="mask-group-green-circle" />
      <img src={ellipseYellowSemiUp} alt="Decorative yellow semi-circle" className="ellipse-yellow-semi-up" />
      <img src={ellipseYellowSunDown} alt="Decorative yellow sun down" className="ellipse-yellow-sun-down" />
      <div class="faq-button-container">
        <button className="faq-cta-button"
            onClick={() => {
              window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank');
            }}>
            Start With Free Consult
        </button>
      </div>
      <div className="tab-titles">
        <h2 className={`tab-title ${activeTab === 'general' ? 'active' : ''}`} onClick={() => setActiveTab('general')}>General Information</h2>
        <h2 className={`tab-title ${activeTab === 'financial' ? 'active' : ''}`} onClick={() => setActiveTab('financial')}>Financial</h2>
      </div>
      <div className="faq-sections">
        {faqs[activeTab].map((faq, index) => (
          <div key={index} className="faq-section">
            <div className="faq-section-header" onClick={() => toggleQuestion(activeTab, index)}>
              {faq.question}
              <span className="faq-icon">{openQuestions[activeTab] === index ? '-' : '+'}</span>
            </div>
            {openQuestions[activeTab] === index && (
              <div className="faq-section-content" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqMain;