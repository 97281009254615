import React from 'react';
import BlogHolder from './BlogHolder';
import './BlogHolder.css';

function BlogPage() {
    return (
        <div className="blog-page">
            <h1 className="page-title">Our Blog</h1>
            <BlogHolder />
        </div>
    );
}

export default BlogPage;
