import React, { useState } from 'react';
import './Testimonials.css'; // Make sure the CSS file is in the same directory

// Placeholder paths for patient images
import patient1 from '../Logos/HomePage/Testimonials/Patient1.png';
import patient2 from '../Logos/HomePage/Testimonials/Patient2.png';
import patient3 from '../Logos/HomePage/Testimonials/Patient3.png';
import patient4 from '../Logos/HomePage/Testimonials/Patient6.png';
import patient5 from '../Logos/HomePage/Testimonials/Patient5.png';
import patient6 from '../Logos/HomePage/Testimonials/Patient4.png';
import patient7 from '../Logos/HomePage/Testimonials/Patient7.png';

const testimonials = [
  {
    name: "Ty",
    age: "",
    quote: "“I am feeling so much better every day since I started working with Dr. Kindervater at Sapia Health. We've done a lot of work with my diet, and I've improved my sleep for the last six months. I've got increased energy, I'm much happier. I'm really thankful for the service.”",
    img: patient4,
  },
  {
    name: "Shana",
    age: "",
    quote: "“Heart disease runs in my family. I've had multiple family members have heart attacks at a young age. When I turned 35 I started to think maybe I should get this checked out. I went and saw my regular doctor. They did one quick lab test, and told me my levels were a bit high, but there was nothing to worry about because I was young. When I saw Dr. Nelson, he shared my concern, and did a much more thorough evaluation of my cardiac risk. He came up with a diet plan for me that not only includes all of my favorite foods, and gets me the nutrients I need. But, I've seen a significant reduction in my cholesterol levels, he shared with me a lot of information about how early prevention makes a huge difference in my risk! I feel better knowing I’m taking care of myself.”",
    img: patient3,
  },
  {
    name: "Patrick",
    age: "",
    quote: "“I  have always struggled with weight. After too many attempts with crash diets and fads, I did a medical weight loss plan with Dr. Kindervater. I lost the weight and kept it off!  Our regular check-ins re-evaluate my nutrition needs now that my weight is at target and my activity level is higher (he set me up with a training plan too). This has been the best money I’ve ever spent.”",
    img: patient1,
  },
  {
    name: "Steve",
    age: "",
    quote: "“Before I went to Sapia Health, I tried going to one of those 'men's health' clinics. It was clear from when I walked in the door that they were going to put me on testosterone whether that was the issue or not. When I saw Dr. Kindervater, we spent a lot of time talking about everything else in my life from diet to lifestyle to family history. We did a couple rounds of lab tests to make sure where my problem was coming from. My testosterone levels were low. But, it was because of a different problem. With some lifestyle changes and supplements, my levels are above where they're supposed to be at this age, and I'm feeling great. I'm really thankful for the holistic approach and the thought that was put into my care.”",
    img: patient5,
  },
  {
    name: "Laura",
    age: "",
    quote: "“I was struggling with fatigue almost daily. I have seen many different doctors, but didn’t really find a source for my symptoms with any of them. When I saw Dr. Kindervater, the first thing we did was review my diet alongside my genetic testing and bloodwork. Not only was I calorie restricting way past what was healthy, I also had a genetic condition that keeps me from absorbing certain nutrients. I was basically starving myself! We’ve done a lot of learning on how to eat better, and I am now on some supplementation to bypass my deficiencies. 10/10. Awesome doctor, and always responds quickly. I love being able to check in whenever I need to.”",
    img: patient2,
  },
  
  {
    name: "Sara",
    age: "",
    quote: "“Next level care! My doc is up to date on the latest tech and testing, but doesn’t push BS procedures or expensive supplements. I do a lot of reading and listening to podcasts. This is the first doctor I’ve seen who actually knows what I’m talking about when I ask questions.”",
    img: patient7,
  },
  {
    name: "Nicole",
    age: "",
    quote: "“I started with Dr. Nelson because I wanted to work on my cognitive health, and focus on limiting effects of aging. He’s an awesome guy. Super great listener. Has been a real asset in navigating the healthcare landscape (or should I say minefield?!). I’m getting amazing care at a fraction of the price it would cost with a typical concierge doctor.”",
    img: patient6,
  },
];

function Testimonials() {
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0]);

  const handleClick = (testimonial) => {
    setCurrentTestimonial(testimonial);
  };

  return (
    <div className="testimonials-container">
      <h1>Hear from Our Patients</h1>
      <div className="patient-photos">
        {testimonials.map((testimonial, index) => (
          <img
            key={index}
            src={testimonial.img}
            alt={`Patient ${index + 1}`}
            onClick={() => handleClick(testimonial)}
            className={testimonial.img === currentTestimonial.img ? "central-patient-photo" : ""}
          />
        ))}
      </div>
      <div className="testimonial-highlight">
        <h2>{currentTestimonial.name}</h2>
        <p className="patient-details">{currentTestimonial.age}</p>
        <blockquote>{currentTestimonial.quote}</blockquote>
      </div>
      <button className="testimonial-consultations-btn" onClick={() => window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank')}>Start with Free Consult</button>
    </div>
  );
}

export default Testimonials;
