import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Logos/LogoLightLogo.png'; // Replace with the path to your logo image
import DropdownMenu from './DropdownMenuLight'; // Assuming DropdownMenu.js does not need .js extension
import styles from './lightHeader.module.css'; // Updated import

function HeaderComponent({ firstSectionClass }) {
  const [showButton, setShowButton] = useState(false);
  const [shouldRenderButton, setShouldRenderButton] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const section = document.querySelector(`.${firstSectionClass}`);
        const header = document.querySelector(`.${styles.header}`);

        if (section && header) {
          const sectionRect = section.getBoundingClientRect();
          const headerRect = header.getBoundingClientRect();

          if (headerRect.bottom > sectionRect.bottom) {
            setShouldRenderButton(true);
            setTimeout(() => setShowButton(true), 10); // Delay to ensure render before adding class
          } else {
            setShowButton(false);
            setTimeout(() => setShouldRenderButton(false), 500); // Delay to ensure fade-out before hiding
          }
        }
      }, 100); // Debounce delay
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [firstSectionClass]);

  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Logo" />
      </Link>
      <div className={styles.buttonWrapper}>
        {shouldRenderButton && (
          <button className={`${styles.ctaButton} ${showButton ? styles.fadeIn : styles.fadeOut}`}
            onClick={() => {
              window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank');
            }}>
            Start With Free Consult
          </button>
        )}
      </div>
      <div className={styles.dropdownMenu}>
        <DropdownMenu />
      </div>
    </header>
  );
}

export default HeaderComponent;
