import React from 'react';
import HeaderComponent from '../Headers/lightHeader';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import TotalHealthAssessment from './TotalHealthAssessment';
import Footer from '../Footer/GreenFooter';

// Import any components specific to the Services page

function ServicesPage() {
  return (
    <div>
      <HeaderComponent firstSectionClass="first-section" />
      <FirstSection className="first-section" />
      <SecondSection />
      <TotalHealthAssessment />
      <Footer />
    </div>
  );
}

export default ServicesPage;
