import React from 'react';
import BlogCard from './BlogCard';
import blogs from './BlogData'; // Import blog data

function BlogHolder() {
    return (
        <div className="blog-holder">
            {blogs.map(blog => (
                <BlogCard blog={blog} key={blog.id} />
            ))}
        </div>
    );
}

export default BlogHolder;
