import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import styles from './DropdownMenuDark.module.css';

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility

  return (
    <div className={styles.dropdown}>
      <button className={`${styles.menuIcon} ${isOpen ? styles.open : ''}`} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '✕' : '☰'} {/* Switches between hamburger and X icons */}
      </button>
      <div className={`${styles.menuContent} ${isOpen ? styles.open : ''}`}>
        <Link to="/" className={styles.link} onClick={() => setIsOpen(false)}>Home</Link>
        <Link to="/services" className={styles.link} onClick={() => setIsOpen(false)}>Services</Link>
        <Link to="/about" className={styles.link} onClick={() => setIsOpen(false)}>About</Link>
        <Link to="/membership" className={styles.link} onClick={() => setIsOpen(false)}>Membership</Link>
        <Link to="/faq" className={styles.link} onClick={() => setIsOpen(false)}>FAQ</Link>
      </div>
    </div>
  );
}

export default DropdownMenu;
