import React from 'react';
import './FirstSection.css'; // Make sure to create and import the corresponding CSS file

function FirstSection({ className }) {
  return (
    <div className={`service-section ${className}`}>
      <div className="services-content">
        <h1>Services</h1>
        <h2>Comprehensive Health Assessment and Plan</h2>
        <p>
          Get straight to the heart of your health with our tele-health services. Included in
          your membership, our Health Assessment and Plan doesn't just scratch the surface—it
          digs deep into your well-being, mapping out a clear, tailor-made path to your health goals.
        </p>
        <div className="service-buttons">
          <button className="service-contact-button" onClick={() => window.location = 'mailto:info@sapiahealth.com'}>
            Contact Us
          </button>
          <button className="service-signup-button" onClick={() => window.open('https://secure.gethealthie.com/appointments/embed_appt?dietitian_id=1218103&provider_ids=%5B1218103,3662894%5D&appt_type_ids=%5B168168%5D&org_level=true', '_blank')}>
            Start With Free Consult
          </button>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
