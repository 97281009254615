import React from 'react';
import Header from '../Headers/darkHeader';
import IntroText from './IntroText';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import HealthPlans from './HealthPlans';
import Testimonials from './Testimonials';
import Footer from '../Footer/GreenFooter';

function HomePage() {
  return (
    <div>
      <Header firstSectionClass="hero-section" />
      <IntroText className="hero-section" />
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      <HealthPlans />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default HomePage;
