import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './homepage/HomePage';
import ServicesPage from './Services/ServicesPage';
import AboutPage from './About/AboutPage';
import HowItWorks from './HowItWorks/HowItWorks';
import Membership from './HowItWorks/membership'; // Ensure the path is correct
import FAQ from './FAQ/FAQ';
import BlogPage from './blog/BlogPage'; // Import the BlogPage
import BlogDetail from './blog/BlogDetail'; // Import the BlogDetail
import ScrollToTop from './ScrollToTop'; // Make sure to import your ScrollToTop component
import FloatingButton from './Footer/FloatingButton'; // Import the Floating Button

function App() {
    const handleClick = () => {
        window.location.href = 'mailto:info@sapiahealth.com'; // Example action on button click
    };

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} /> 
                <Route path="/about" element={<AboutPage />} /> 
                <Route path="/membership" element={<HowItWorks />} /> 
                <Route path="/faq" element={<FAQ />} /> 
                <Route path="/membership" element={<Membership />} /> 
                <Route path="/blog" element={<BlogPage />} /> 
                <Route path="/blog/:id" element={<BlogDetail />} /> {/* No need to pass blogs as prop */}
            </Routes>
            {/* Add the Floating Button */}
            <FloatingButton onClick={handleClick} />
        </Router>
    );
}

export default App;
